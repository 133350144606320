/* @import url('https://fonts.googleapis.com/css2?family=BIZ+UDPGothic&display=optional'); */

/*エリア*/
.pc-container {
  position: relative;
  padding: 0!important;
}
/*ロゴ*/
.logoArea {
  position: fixed!important;
  top: 10px!important;
  left: 10px!important;
  z-index: 50!important;
  width: 50%;
}
.logoArea a {
  display: block;
}
.logoArea a img {
  max-width: 120px;
}
/*管理メニュー*/
/* .settingArea {
  position: fixed;
  top: 10px!important;
  right: 10px!important;
  z-index: 100!important;
  width: 50%;
  max-width: 50px;
} */
.settingBtn{
  width: 100px;
  right: 0;
}
.notificationBtn{
  top:0;
  width: 100px;
  left: 50;
}
.settingBtn svg{
  transform: scale(0.5);
}
/*アベレージ*/
.average-score {
  position: absolute;
  /* color: rgb(50 0 255 / var(--tw-text-opacity)); */
  /* font-size: 3.75rem; */
  text-shadow: 2px 2px 4px #565656;
  line-height: 1;
  top: 40px;
  left: 10px;
  z-index: 30;
  width: 100px;
  display: block;
}

/* 画像メニュー*/
.img-menu {
  position: absolute;
  /* color: rgb(50 0 255 / var(--tw-text-opacity)); */
  /* font-size: 3.75rem; */
  text-shadow: 2px 2px 4px #565656;
  line-height: 1;
  top: 40px;
  right: 10px;
  z-index: 30;
  width: 100px;
  display: block;
}
.avgScoreBox {
  width: 100px;
  text-align: left;
}
.avgScoreBox span {
  display: inline-block;
  /* text-shadow: 2px 2px 4px rgba(0,0,0,0); */
  text-shadow: 2px 2px 4px #565656;
  color: #FFF;
  /* color: rgb(215, 0, 255, 1); */
}
.avgScoreBox span.avgScore {
  /* font-weight: bold; */
  /* font-family: "BIZ UDPGothic", sans-serif; */
  font-style: normal;
  font-weight: 400;
  letter-spacing: -.08em;
  font-size: min(7.5vw,34px);
}
.avgScoreBox span.avgTxt {
  /* font-family: "BIZ UDPGothic", sans-serif; */
  font-style: normal;
  font-weight: 400;
  padding-left: .25em;
  font-size: min(3vw,22px);
}
/*スコア*/
.score-number-container {
  position: absolute;
  top: 50%;
  inset: 0px;
  z-index: 30;
  bottom: 0.25rem;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}
.image-content-container {
  position: absolute;
  top: 50%
}
.scoreInfoArea {
  position: absolute;
  bottom: 5%;
  left: 5%;
  transform: translate(-50%, -50%);
  width: 100%;
  margin: 0 auto;
  text-align: center;
}
.score-number {
  /* font-family: "BIZ UDPGothic", sans-serif; */
  font-style: normal;
  line-height: 1;
  letter-spacing: -.05em;
  font-size: min(28vw,120px);
  text-shadow: 2px 2px 4px #565656;
  color: white;
}
.cityBtn {
  margin-top: 5px;
  font-size: min(3vw,16px);
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.city-name,
.account-name {
  font-size: min(3.2vw,16px);
  /* font-family: "BIZ UDPGothic", sans-serif; */
  font-style: normal;
  font-weight: 400;
  line-height: 1.1;
}
.accountBtn {
  line-height: 1.1;
  /* margin-top: 5px; */
}

/*.locate-icon {
  position: absolute;
  left: 7%;
}
.city-name {
  position: absolute;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
  .account-name {
  position: absolute;
} */

/* 
.score-number-container{
  top: 50% !important;
} */

/* .city-name, .account-name, .locate-icon  {
  top: 70%;
  font-size: 2.5rem;
} */

.locate-icon {
  display: inline;
}
.mapMark {
  display: none;
}
.plusActive,
.plusDivActive {
  width: 1.5rem!important;
  height: 1.5rem!important;
}
@media screen and (max-width: 450px) {
  .logoArea a img {
      max-width: 100px;
  }
}

@media(min-width: 1441px) {
  
  /* .score-number-container{
      top: 40% !important;
  }
  .score-number {
      font-size: 12rem !important;
  } */
  .locate-icon {
      width: 48px;
      height: 48px;
  }
  /* .score-number {
      left: 50%;
      font-size: 8rem;
      font-family: "BIZ UDPGothic", sans-serif;
      font-style: normal;
      font-weight: bold;
      line-height: 1;
      letter-spacing: -.05em;
      color: #FFF;
  } */
} 
@media(max-width: 1440px) {
 
  .scoreInfoArea {
      left: 10%;
  }
  /* .score-number-container {
      top: 20% !important;
  }
  .score-number {
      font-size: 6rem !important;
  } */
}
@media(max-width: 1280px) {
 

  /* .score-number-container {
      top: 25% !important;
  }
  .score-number {
      font-size: 6rem !important;
  } */
}
@media(max-width: 768px) {
  .scoreInfoArea {
      left: 15%;
  }
}
@media(max-width: 568px) {
  .scoreInfoArea {
      bottom: 5%;
      left: 15%;
  }
  
  .score-number {
      font-size: 5rem !important;
  }
}
@media(max-width: 380px) {
  /* .score-number-container {
      top: 20% !important;
  }
  .score-number {
      font-size: 5rem !important;
  } */
}
/* iPhone SE */
@media (aspect-ratio: 375/667) {
}
/* iPhone XR */
@media (aspect-ratio: 414/896),
/* iPhone 12 Pro */
(aspect-ratio: 390/844),
/* iPhone 14 Pro Max */
(aspect-ratio: 430/932),
/* Pixel 7 */
(aspect-ratio: 412/915)
{
  /* .score-number-container{
      top: 50% !important;
  } */
}
/* Sumsung Galaxy S8+ */
@media(aspect-ratio: 360/740) {
  /* .score-number-container{
      top: 50% !important;
  } */
}
/* iPad Pro */
@media(aspect-ratio: 1024/1366) {
  /* .score-number {
      font-size: 15rem !important;
  }
  .score-number-container {
      top: 45% !important;
  } */
}
/* Surface Pro 7 */
@media(aspect-ratio: 912/1368), 
(aspect-ratio: 540/720) {
  /* .score-number {
      font-size: 15rem !important;
  }
  .score-number-container{
      top: 50% !important;
  } */
}
/* iPad Air */
@media(aspect-ratio: 820/1180),
/* Surface Pro 7 */
(aspect-ratio: 912/1368) {
  /* .score-number {
      font-size: 10rem !important;
  }
  .score-number-container{
      top: 50% !important;
  } */
}
/* iPad mini */
@media(aspect-ratio: 768/1024) {
  /* .score-number-container{
      top: 50% !important;
  } */
}
/* Surface Duo */
@media(aspect-ratio: 540/720) {
  /* .score-number {
      font-size: 8rem !important;
  }
  .score-number-container{
      top: 40% !important;
  } */
}
/* Galaxy Z Fold5 */
@media(aspect-ratio: 344/882) {
  /* .score-number-container{
      top: 50% !important;
  }*/
} 
/* Asusu Zen book Fold */
@media(aspect-ratio: 853/1280)
{
  /* .score-number {
      font-size: 10rem !important;
  }
  .score-number-container{
      top: 55% !important;
  } */
}
/* Sumsung Galaxy A51/71 */
@media(aspect-ratio: 412/914) {
  /* .score-number-container{
      top: 55% !important;
  } */
}
@media(aspect-ratio: 1280/800) {
  /* .score-number {
      font-size: 10rem !important;
  }
  .score-number-container{
      top: 30% !important;
  } */
}
@media(aspect-ratio: 1024/600) {
  /* .score-number-container {
      top: 30% !important;
  } */
}
/* iPhone 11s */
@media(aspect-ratio: 414/896) {
  /* .score-number-container {
      top: 30% !important;
  }
  .score-number {
      font-size: 8rem !important;
  } */
}

